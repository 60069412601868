import styled from 'styled-components'

export const SimpleTable = styled.table`
  border: 1px solid;
  border-collapse: collapse;
  border-color: ${({ theme }) => theme.colors.border};
  margin: 8px;
`
export const SimpleTh = styled.th`
  border-top: 1px solid;
  border-bottom: 1px dotted;
  border-right: 1px solid;
  border-left: 1px solid;
  border-collapse: collapse;
  border-color: ${({ theme }) => theme.colors.border};
  padding: 1px 5px;
  white-space: nowrap;
`
export const SimpleTd = styled.td`
  border-top: 1px dotted;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  border-collapse: collapse;
  border-color: ${({ theme }) => theme.colors.border};
  padding: 1px 5px;
  white-space: nowrap;
`
